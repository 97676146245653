import React from 'react';
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import './customStyle.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { topMenuItems, MAIN_MENU_TYPE, topMenuItemsWithPayments } from '../../../constants/menuItems.js';
import { MOBILE_BURGER_MENU_STYLES } from '../../../constants/mobile';
import { Link } from 'react-router-dom';
import { SignOut } from 'phosphor-react';
import { LogOutSvg } from '../Icons';
import './menu.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/slice/authSlice';
import { slide as BurgerMenu } from 'react-burger-menu';
import { FormattedMessage } from 'react-intl';
import { getPaymentsEnablePayment, getProperLogo, getPaymentsEnableWebTrader } from '../../../utils/selectors';
import { useAppSelector } from '../../../store';
import { selectChatUnreadThreads } from "../../../store/slice/chat/chatSlice";
import { ChatIcon } from "../../../pages/Chat/components/ChatIcon/ChatIcon";
import cn from "classnames";
import { isChatEnabled } from "../../../pages/Chat/helpers/isChatEnabled";

const Menu = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatUnreadCount = useSelector(selectChatUnreadThreads);
  const chatEnabled = isChatEnabled();
  const ENABLE_PAYMENT = useAppSelector(getPaymentsEnablePayment);
  const ENABLE_WT = useAppSelector(getPaymentsEnableWebTrader)
  const CURRENT_MENU_WITH_PAYMENTS = ENABLE_PAYMENT ? topMenuItemsWithPayments : topMenuItems

  // 2-ой костыль, после ENABLE_PAYMENT
  const items = CURRENT_MENU_WITH_PAYMENTS.map((item) => {
    if (ENABLE_WT && item.itemId === '/trading') {
      return { ...item, subNav: item.subNav.filter((sub) => sub.itemId !== '/opened-positions' && sub.itemId !== '/deals') }
    } else {
      return item
    }
  })

  const topLevelPath = items.filter((item) => {
    if (item['subNav'] && item['subNav'].length) {
      return item;
    }
  });

  const logo = useSelector(getProperLogo)

  const bigMenu = (
    <div className={'sidebar side-navigation-panel'}>
      <div className={'sidebar__top'}>
        <Link
          to="/finance" className={'logo'}
          // style={{ marginLeft: type === MAIN_MENU_TYPE.SIDE_MENU ? 50 : 12 }}
        >
          <img src={logo} alt="logo" />
        </Link>
        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            const exist = topLevelPath.find((topItem) => topItem.itemId.includes(itemId));
            if (!exist) {
              navigate(itemId);
            }
          }}
          items={items}
        />
      </div>
      <div className={'sidebar__bottom'}>
        {/*{<Navigation*/}
        {/*  activeItemId={location.pathname}*/}
        {/*  items={bottomMenuitems}*/}
        {/*  onSelect={({ itemId }) => {*/}
        {/*    navigate(itemId);*/}
        {/*  }}*/}
        {/*/>}*/}
        {chatEnabled ? (
            <Link className={cn('btn-menu', { active: location.pathname === '/chat'})} to={'/chat'}>
              <ChatIcon messagesCount={chatUnreadCount}/>
              <span>Support</span>
            </Link>
        ) : null}
        <button className={'btn-menu'} onClick={() => {
          dispatch(logout())
          navigate('/')
        }}>
          <LogOutSvg style={{flexShrink: 0}}/>
          <FormattedMessage id="menu.logout" defaultMessage="Logout" />
        </button>
      </div>
    </div>
  );

  const burgerMenu = (
    <BurgerMenu styles={MOBILE_BURGER_MENU_STYLES}>
      <div className={'sidebar-burger side-navigation-panel'}>
        <div className={'sidebar__top'}>
          <Link to="/finance" className={'logo'}>
            <img src={logo} alt="logo" />
            {/*{logo_src ? (*/}
            {/*  <img src={logo_src} alt="logo" />*/}
            {/*) : (*/}
            {/*  <svg>*/}
            {/*    <use href="#logo"></use>*/}
            {/*  </svg>*/}
            {/*)}*/}
          </Link>
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              const exist = topLevelPath.find((topItem) => topItem.itemId.includes(itemId));
              if (!exist) {
                navigate(itemId);
              }
            }}
            items={items}
          />
        </div>
        <div className={'sidebar__bottom'}>
          {chatEnabled ? (
              <Link className={cn('btn-menu', { active: location.pathname === '/chat'})} to={'/chat'}>
                <ChatIcon messagesCount={chatUnreadCount}/>
                <span>Support</span>
              </Link>
          ) : null}
          <button className={'btn-menu'} onClick={() => {
            dispatch(logout())
            navigate('/')
          }}>            
            <SignOut size={24} style={{flexShrink: 0}}/>
            <FormattedMessage id="menu.logout" defaultMessage="Logout" />
          </button>
        </div>
      </div>
    </BurgerMenu>
  );

  const render = () => {
    switch (type) {
      case MAIN_MENU_TYPE.SIDE_MENU:
        return bigMenu;
      case MAIN_MENU_TYPE.BURGER:
        return burgerMenu;
      default:
        return bigMenu;
    }
  };

  return render();
};

export default Menu;
