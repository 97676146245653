import React from 'react';
import { useAppSelector } from 'store';
import { selectIsFintegrity } from 'store/slice/paymentsCurrency';
import { WalletPopupContentFintegrity } from './WalletPopupContentFintegrity';
import { WalletPopupContent } from './WalletPopupContent';

const WalletPopup = (props) => {
  const isFintegrity = useAppSelector(selectIsFintegrity);

  return isFintegrity ? (
    <WalletPopupContentFintegrity {...props} />
  ) : (
    <WalletPopupContent {...props} />
  );
};

export default WalletPopup;
