import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import './wallet-popup.scss';
import Button from '../../Button/Button';
import PopupTemplate from '../../PopupTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { addDeposit, depositMethods } from '../../../../api/deposit';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import Preloader from '../../Preloader/Preloader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../../utils/notify';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import './wallet.scss';

const cryptoWallets = {
  btc: 'Bitcoin',
  ltc: 'Litecoin',
  eth: 'Ethereum',
  dash: 'Dash',
  doge: 'Doge',
  usdt: 'Tether',
  usdc: 'USDC',
  trx: 'Tron',
};

const methodOptions = [
  {
    value: 'Crypto',
    label: 'Crypto',
  },
  {
    value: 'credit_card',
    label: 'Credit Card',
  },
];

export const WalletPopupContent = ({ trigger, onCloseModal, onSuccess, onError, paymentData }) => {
  const [isMethodLoad, setIsMethodLoad] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [cryptoTransaction, setCryptoTransaction] = useState(null);
  const [isPaymentRequestLoad, setPaymentRequestLoad] = useState(false);
  const componentMounted = useRef(true);
  const [fullWallet, setFullWallet] = useState(false);
  const intl = useIntl();

  const { handleSubmit, register, reset } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      target_wallet: null,
      method: null,
    },
  });

  const getMethods = () => {
    setIsMethodLoad(true);
    depositMethods()
      .then((res) => {
        if (componentMounted.current && !res.errors) {
          if (res.data.data && res.data.data['electronic'] && res.data.data['electronic'].length) {
            const wallets = res.data.data['electronic'].sort((a, b) => b.fintegrity - a.fintegrity);
            setWalletList(wallets);
            setSelectedWalletId(wallets[0].id);
            reset({ target_wallet: wallets[0].id.toString() });
          }
        }
      })
      .catch(console.log)
      .finally(() => setIsMethodLoad(false));
  };

  const onSubmit = async (close, data) => {
    setPaymentRequestLoad(true);

    addDeposit({
      ...paymentData,
      method: methodOptions[0].value,
      target_wallet: data['target_wallet'],
    })
      .then((res) => {
        setPaymentRequestLoad(false);

        if (res?.data?.data) {
          const resp = res.data.data;

          if (resp.crypto_transaction && resp.crypto_transaction.wallet) {
            setCryptoTransaction({
              cryptoType: resp.crypto_transaction.wallet.crypto_type,
              wallet: resp.crypto_transaction.wallet.wallet,
              amount: resp.crypto_transaction.crypto_amount,
            });
          }
        }

        NotificationManager.success(
          intl.formatMessage({ id: 'transaction.deposit.alert.created' }),
          intl.formatMessage({ id: 'status.success.title' }),
          3000,
        );
        onSuccess();
      })
      .catch((err) => {
        setPaymentRequestLoad(false);
        onError();
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        });
      });
  };

  const closeModal = (closeModal) => {
    if (walletList && walletList.length) {
      reset({
        target_wallet: walletList[0].id.toString(),
        method: methodOptions[0],
      });
      setSelectedWalletId(walletList[0].id);
    } else {
      reset({
        target_wallet: undefined,
        method: methodOptions[0],
      });
      setSelectedWalletId(null);
    }
    setCryptoTransaction(null);
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        onOpen={getMethods}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage
                id="page.finance.deposit.popup.wallet"
                defaultMessage="Crypto deposit"
              />
            }
            styles={{
              popup: {
                maxWidth: '500px',
              },
            }}
            rightContent={
              <div className={'content'}>
                {isPaymentRequestLoad ? <Preloader /> : null}
                {!cryptoTransaction && walletList?.length ? (
                  <form
                    className={`filters-form ${isPaymentRequestLoad ? 'blur' : ''}`}
                    onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                  >
                    <Container>
                      <div className={'wallet-list'}>
                        <Row>
                          {walletList.length &&
                            walletList.map((item, index) => (
                              <Col key={item.id} md={6} sm={12}>
                                <div className={'wallet'}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    id={item.id}
                                    autoComplete="off"
                                    value={item.id}
                                    {...register('target_wallet')}
                                  />
                                  <label htmlFor={item.id}>
                                    <div
                                      className={`
                                        wallet__item
                                        ${selectedWalletId === item.id ? 'selected' : ''}
                                      `}
                                      onClick={() => {
                                        if (selectedWalletId !== item.id) {
                                          setSelectedWalletId(item.id);
                                        }
                                      }}
                                    >
                                      <div className={'wallet__icon'}>
                                        <i className={`${item.crypto_type?.toLowerCase()}`}></i>
                                      </div>
                                      <div className={'wallet__desc'}>
                                        <span>
                                          {cryptoWallets[item.crypto_type?.toLowerCase()]}{' '}
                                          {item.crypto_type === 'USDT' &&
                                            item.crypto_network &&
                                            `(${item.crypto_network})`}
                                        </span>
                                        <span onClick={() => setFullWallet(true)}>
                                          {!fullWallet
                                            ? item?.wallet.slice(0, 5) +
                                              '...' +
                                              item?.wallet.slice(-5)
                                            : item?.wallet}
                                        </span>
                                        <span>{item?.description}</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </Container>
                  </form>
                ) : null}

                {cryptoTransaction ? (
                  <div className={'transaction__info'}>
                    <div className={'transaction__qr'}>
                      <QRCode value={`${cryptoTransaction.wallet}`} size={100} />
                    </div>
                    <div className={'transaction__desc'}>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text1"
                          defaultMessage="Please send"
                        />{' '}
                        <b>{cryptoTransaction.amount}</b> {cryptoTransaction.cryptoType}{' '}
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text2"
                          defaultMessage="to this wallet address"
                        />
                      </span>
                      <span
                        className={'copy_id'}
                        onClick={() =>
                          copyToBuffer(
                            cryptoTransaction.wallet,
                            false,
                            intl.formatMessage({ id: 'copied.to.buffer.alert' }),
                          )
                        }
                      >
                        {cryptoTransaction.wallet}
                      </span>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text3"
                          defaultMessage="Funds are credited after confirmation."
                        />
                      </span>
                    </div>
                  </div>
                ) : null}

                {!cryptoTransaction && !walletList?.length ? (
                  <span className={'transaction__empty'}>
                    {isMethodLoad ? 'Loading..' : 'No Wallets'}
                  </span>
                ) : null}
              </div>
            }
            footer={
              !cryptoTransaction && (
                <form
                  className={`filters-form ${isPaymentRequestLoad ? 'blur' : ''}`}
                  onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                  style={{ paddingRight: 24, paddingBottom: 24 }}
                >
                  <div className={'content-controls'}>
                    <div className={'controls__buttons'}>
                      <Button
                        buttonText={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        buttonType="secondary"
                        size="big"
                        onClick={closeModal.bind(undefined, close)}
                      />
                      <Button buttonText={'OK'} buttonType="primary" size="big" type="submit" />
                    </div>
                  </div>
                </form>
              )
            }
          />
        )}
      </Popup>
    </>
  );
};
