import React from 'react';

import './select.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import ReactSelect from 'react-select';
import { FormattedMessage } from 'react-intl';

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    border: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
    backgroundColor: 'var(--body-background-color)',
    borderColor: state.isFocused ? 'var(--main-color)' : 'hsl(0, 0%, 80%)',
    boxShadow: state.isFocused ? '0 0 0 1px var(--main-color)' : 'unset',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 0 2px 8px',
    height: '41px',
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%',
    maxHeight: 200,
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: 'normal',
  }),
  option: (styles, state) => {
    return {
      ...styles,
      background: state.isSelected ? 'var(--main-light--color) !important' : 'transparent',
      color:
        state.isSelected && state.isFocuse
          ? 'var(--primary-text-color)'
          : 'var(--primary-text-color)',
      ':hover': {
        background: 'var(--main-light--color)  !important',
      },
    };
  },
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
};

const Select = (props) => {
  const { field } = useController(props);

  const filterOptions = (data, input) => {
    const { label } = data;
    if (input) {
      return !!label.toLowerCase().startsWith(input.toLowerCase());
    }
    return true;
  };

  return (
    <div className={'select__input'}>
      <div className={'label-wrap'}>
        {props.label && (
          <div className={props.errors ? 'label error' : 'label'}>
            {props.label}
            {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
          </div>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? 'select-wrap error' : 'select-wrap'}>
        <ReactSelect
          {...field}
          className={props.errors ? 'select error' : 'select'}
          options={props.options}
          classNamePrefix={props.classNamePrefix ? props.classNamePrefix : ''}
          placeholder={
            props.placeholder || (
              <FormattedMessage id="placeholder.select" defaultMessage="Select" />
            )
          }
          getOptionValue={props.getOptionValue}
          getOptionLabel={props.getOptionLabel}
          styles={customStyles}
          isDisabled={props.disabled}
          filterOption={filterOptions}
          onChange={(option) => {
            field.onChange(option);
            props?.onChange?.(option);
          }}
        />

        {props.errors && <p className={'errorName'}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default Select;
