import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './wallet-popup.scss';
import Button from '../../Button/Button';
import PopupTemplate from '../../PopupTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { addDeposit, depositCryptoWallets } from 'api/deposit';
import { useForm, useWatch } from 'react-hook-form';
import Select from '../../Select/Select';
import { Col, Container, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import Preloader from '../../Preloader/Preloader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from 'utils/notify';
import { copyToBuffer } from 'utils/copyToBuffer';
import { useAppSelector } from 'store';
import { selectPaymentsCrypto } from 'store/slice/paymentsCurrency';
import './wallet.scss';

const methodOptions = [
  {
    value: 'Crypto',
    label: 'Crypto',
  },
  {
    value: 'credit_card',
    label: 'Credit Card',
  },
];

const generateOption = (value) => ({ label: value, value });

const initialWalletState = {
  data: [],
  page: 1,
  isLoading: false,
  hasMore: false,
};

const formDefaultValues = {
  target_wallet: null,
  network: null,
  currency: null,
};

export const WalletPopupContentFintegrity = ({
  trigger,
  onCloseModal,
  onSuccess,
  onError,
  paymentData,
}) => {
  const intl = useIntl();
  const [cryptoWallets, setCryptoWallets] = useState(initialWalletState);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [cryptoTransaction, setCryptoTransaction] = useState(null);
  const [isPaymentRequestLoading, setPaymentRequestLoading] = useState(false);
  const [fullWallet, setFullWallet] = useState(false);

  const crypto = useAppSelector(selectPaymentsCrypto);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      target_wallet: null,
      network: null,
      currency: null,
    },
  });

  const watch = useWatch({ control });

  const currencyOptions = Object.keys(crypto).map(generateOption);
  const networkOptions = (crypto[watch.currency?.value] || []).map(generateOption);

  const onSubmit = async (close, data) => {
    setPaymentRequestLoading(true);

    addDeposit({
      ...paymentData,
      method: methodOptions[0].value,
      target_wallet: data['target_wallet'],
    })
      .then((res) => {
        setPaymentRequestLoading(false);

        if (res?.data?.data) {
          const resp = res.data.data;

          if (resp.crypto_transaction && resp.crypto_transaction.wallet) {
            setCryptoTransaction({
              cryptoType: resp.crypto_transaction.wallet.crypto_type,
              wallet: resp.crypto_transaction.wallet.wallet,
              amount: resp.crypto_transaction.crypto_amount,
            });
          }
        }

        NotificationManager.success(
          intl.formatMessage({ id: 'transaction.deposit.alert.created' }),
          intl.formatMessage({ id: 'status.success.title' }),
          3000,
        );
        onSuccess();
      })
      .catch((err) => {
        setPaymentRequestLoading(false);
        onError();
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        });
      });
  };

  const closeModal = (closeModal) => {
    reset(formDefaultValues);
    setSelectedWalletId(null);
    setCryptoWallets(initialWalletState);
    setCryptoTransaction(null);
    closeModal();
    onCloseModal();
  };

  const cryptoWalletsParams = {
    currency: watch.currency?.value,
    network: watch.network?.value,
    target_wallet: watch.target_wallet,
    page: cryptoWallets.page,
    per_page: 20,
  };

  const depositCryptoWalletsRequest = (params?: any) => {
    setCryptoWallets((prev) => ({ ...prev, isLoading: true }));

    depositCryptoWallets({ ...cryptoWalletsParams, ...(!!params ? params : {}) })
      .then((response) => {
        const { data, meta } = response.data;
        const hasMore = meta.current_page < meta.last_page;

        setCryptoWallets((prev) => ({
          ...prev,
          data: meta.current_page > 1 ? data.concat(prev.data) : data,
          page: hasMore ? prev.page + 1 : prev.page,
          hasMore,
        }));
      })
      .catch(console.log)
      .finally(() => setCryptoWallets((prev) => ({ ...prev, isLoading: false })));
  };

  const onChangeCurrency = (option) => {
    setValue('currency', option);
    setValue('network', null);
    setValue('target_wallet', null);
    setCryptoWallets(initialWalletState);

    depositCryptoWalletsRequest({ currency: option.value, network: null, page: 1 });
  };

  const onChangeNetwork = (option) => {
    setValue('network', option);
    setValue('target_wallet', null);
    depositCryptoWalletsRequest({ network: option.value, page: 1 });
  };

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage
                id="page.finance.deposit.popup.wallet"
                defaultMessage="Crypto deposit"
              />
            }
            styles={{
              popup: {
                maxWidth: '500px',
              },
            }}
            rightContent={
              <div className={`content ${!cryptoTransaction ? 'full' : ''}`}>
                {isPaymentRequestLoading ? <Preloader /> : null}
                {!cryptoTransaction ? (
                  <Container>
                    <Row>
                      <Col md={12}>
                        <Select
                          id="currency"
                          name="currency"
                          label={
                            <FormattedMessage
                              id="page.finance.deposit.popup.wallet.chooseCurrency"
                              defaultMessage="Choose currency"
                            />
                          }
                          control={control}
                          options={currencyOptions}
                          errors={errors.currency}
                          onChange={onChangeCurrency}
                          rules={{
                            required: (
                              <FormattedMessage
                                id="form.error.requiredFieldnoName"
                                defaultMessage="Field is required"
                              />
                            ),
                          }}
                        />
                        <Select
                          id="network"
                          name="network"
                          label={
                            <FormattedMessage
                              id="page.finance.deposit.popup.wallet.chooseNetwork"
                              defaultMessage="Choose network"
                            />
                          }
                          control={control}
                          options={networkOptions}
                          disabled={!networkOptions.length}
                          onChange={onChangeNetwork}
                        />
                      </Col>
                    </Row>
                    <div className={'wallet-list'}>
                      <Row>
                        {cryptoWallets.data?.length
                          ? cryptoWallets.data.map((cryptoWallet) => (
                              <Col key={cryptoWallet.id} md={6} sm={12}>
                                <div className={'wallet'}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    id={String(cryptoWallet.id)}
                                    autoComplete="off"
                                    value={cryptoWallet.id}
                                    {...register('target_wallet', { required: true })}
                                  />
                                  <label htmlFor={String(cryptoWallet.id)}>
                                    <div
                                      className={`
                                        wallet__item
                                        ${selectedWalletId === cryptoWallet.id ? 'selected' : ''}
                                        ${errors.target_wallet ? 'error' : ''}
                                      `}
                                      onClick={() => {
                                        if (selectedWalletId !== cryptoWallet.id) {
                                          setSelectedWalletId(cryptoWallet.id);
                                        }
                                      }}
                                    >
                                      <div className={'wallet__icon'}>
                                        <i
                                          className={`${cryptoWallet.crypto_type?.toLowerCase()}`}
                                        ></i>
                                      </div>
                                      <div className={'wallet__desc'}>
                                        <span>
                                          {cryptoWallets[cryptoWallet.crypto_type?.toLowerCase()]}{' '}
                                          {cryptoWallet.crypto_type === 'USDT' &&
                                            cryptoWallet.crypto_network &&
                                            `(${cryptoWallet.crypto_network})`}
                                        </span>
                                        <span onClick={() => setFullWallet(true)}>
                                          {!fullWallet
                                            ? cryptoWallet?.wallet.slice(0, 5) +
                                              '...' +
                                              cryptoWallet?.wallet.slice(-5)
                                            : cryptoWallet?.wallet}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </Col>
                            ))
                          : null}
                      </Row>
                    </div>
                  </Container>
                ) : null}

                {cryptoTransaction ? (
                  <div className={'transaction__info'}>
                    <div className={'transaction__qr'}>
                      <QRCode value={`${cryptoTransaction.wallet}`} size={100} />
                    </div>
                    <div className={'transaction__desc'}>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text1"
                          defaultMessage="Please send"
                        />{' '}
                        <b>{cryptoTransaction.amount}</b> {cryptoTransaction.cryptoType}{' '}
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text2"
                          defaultMessage="to this wallet address"
                        />
                      </span>
                      <span
                        className={'copy_id'}
                        onClick={() =>
                          copyToBuffer(
                            cryptoTransaction.wallet,
                            false,
                            intl.formatMessage({ id: 'copied.to.buffer.alert' }),
                          )
                        }
                      >
                        {cryptoTransaction.wallet}
                      </span>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text3"
                          defaultMessage="Funds are credited after confirmation."
                        />
                      </span>
                    </div>
                  </div>
                ) : null}

                {!cryptoTransaction && !cryptoWallets.data?.length ? (
                  <span className={'transaction__empty'}>
                    {cryptoWallets.isLoading ? 'Loading..' : 'No Wallets'}
                  </span>
                ) : null}
                {!cryptoTransaction && cryptoWallets.data.length > 0 && cryptoWallets.hasMore ? (
                  <div className="transaction__loadMoreBtn">
                    <Button
                      buttonText={intl.formatMessage({
                        id: 'page.trading.popup.wallet.loadMore',
                        defaultMessage: 'Load more',
                      })}
                      showSpinner={cryptoWallets.isLoading}
                      isDisabled={cryptoWallets.isLoading}
                      onClick={() => depositCryptoWalletsRequest()}
                    />
                  </div>
                ) : null}
              </div>
            }
            footer={
              !cryptoTransaction && (
                <form
                  className={`filters-form ${isPaymentRequestLoading ? 'blur' : ''}`}
                  onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                  style={{ paddingRight: 24, paddingBottom: 24 }}
                >
                  <div className={'content-controls'}>
                    <div className={'controls__buttons'}>
                      <Button
                        buttonText={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        buttonType="secondary"
                        size="big"
                        onClick={closeModal.bind(undefined, close)}
                      />
                      <Button
                        disabled={!watch.currency?.value || !watch.target_wallet}
                        buttonText={'OK'}
                        buttonType="primary"
                        size="big"
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              )
            }
          />
        )}
      </Popup>
    </>
  );
};
