import React from 'react';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import ControlsGroup from '../../../components/ui/ControlsGroup/ControlsGroup';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store';
import { getPaymentsEnableWebTrader } from 'utils/selectors';
import { TradingHistoryWT } from './TradingHistoryWT';
import { TradingHistory } from './TradingHistory';

const History = () => {
  const ENABLE_WT = useAppSelector(getPaymentsEnableWebTrader);

  return (
    <DashboardLayout>
      <Page
        title={
          <FormattedMessage id="page.trading.history.title" defaultMessage="Trading History" />
        }
      >
        <ControlsGroup>{ENABLE_WT ? <TradingHistoryWT /> : <TradingHistory />}</ControlsGroup>
      </Page>
    </DashboardLayout>
  );
};

export default History;
