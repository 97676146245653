import React from 'react';
import { makeTableData } from '../../../../helpers/table.helper';
import { TABLE_COLUMNS_TRADING_HISTORY_WT } from '../../../../constants/table';
import Table from '../Table';
import cn from 'classnames';
import styles from './transaction-history.module.scss';

const getDateAndTime = (date: string | null) => {
  if (!date) return null;

  const [d, t] = date.split(' ');

  return { date: d, time: t };
};

export const TransactionHistoryWTTable = ({ data, perPage, isLoading }) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const {
      order_id,
      login,
      symbol,
      opening_date,
      closing_date,
      type,
      state,
      units,
      volume,
      lots,
      opening_price,
      closing_price,
      stop_loss_value,
      take_profit_value,
      commission,
      pl,
    } = item;

    const open = getDateAndTime(opening_date);
    const close = getDateAndTime(closing_date);

    return {
      order_id: <b>{order_id}</b>,
      account: login,
      symbol: <span>{symbol}</span>,
      opening_date: open ? (
        <div className={styles['date-cell']}>
          <span>{open.date}</span>
          <span>{open.time}</span>
        </div>
      ) : (
        ''
      ),
      closing_date: close ? (
        <div className={styles['date-cell']}>
          <span>{close.date}</span>
          <span>{close.time}</span>
        </div>
      ) : (
        ''
      ),
      type: <span className={styles[type.toLowerCase()]}>{type}</span>,
      state: state,
      units: units,
      volume: volume,
      lots: lots,
      opening_price: opening_price,
      closing_price: closing_price ?? '',
      sl: stop_loss_value ?? '',
      tp: take_profit_value ?? '',
      commission: commission,
      profit: (
        <div className={cn(pl > 0 ? styles.plPos : pl < 0 ? styles.plNeg : '')}>{pl ?? ''}</div>
      ),
    };
  });

  return (
    <Table
      className={styles['transaction-history-table']}
      columns={TABLE_COLUMNS_TRADING_HISTORY_WT}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={isLoading}
      getCellProps={(cellInfo) => {
        const { row } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : 'var(--body-background-color)'}`,
          },
        };
      }}
    />
  );
};
