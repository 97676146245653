import API from '../api';

export const addDeposit = (postData) => API.post(`/api/v1/deposit/transaction`, postData);
export const balance = () => API.get(`/api/v1/deposit/balance`);
export const depositTransactionList = (params) => API.get(`/api/v1/deposit/transaction?${params}`);
export const depositTransaction = (id, params) =>
  API.get(`/api/v1/trading-account/${id}?${params}`);
export const methods = (id) => API.get(`/api/v1/trading-account/${id}`);
export const types = (id) => API.get(`/api/v1/trading-account/${id}`);
export const withdrawCancel = (id) => API.post(`/api/v1/deposit/cancel-withdrawal/${id}`);
export const transferFunds = (postData) => API.post(`/api/v1/deposit/transfer-funds`, postData);
export const depositMethods = () => API.get(`/api/v1/deposit/methods`);
export const depositCryptoWallets = (params) =>
  API.get(`/api/v1/deposit/crypto-wallets`, { params });
