import React, { useEffect, useState } from 'react';
import { getTradingHistoryWT } from 'api/trading';
import { TransactionHistoryWTTable } from 'components/ui/Table/TransactionHistoryWTTable';
import TablePagination from 'components/ui/TablePagination/TablePagination';
import { TradingHistoryFiltersWT } from './TradingHistoryFiltersWT/TradingHistoryFiltersWT';

export const TradingHistoryWT = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total_items: 0,
    total_pages: 1,
  });

  const [filters, setFilters] = useState(null);

  const tradingHistoryRequest = (params?: any) => {
    setIsLoading(true);
    getTradingHistoryWT({
      current_page: pagination.current_page,
      per_page: pagination.per_page,
      ...params,
    })
      .then((response) => {
        const { items, pagination } = response.data.data;

        setTableData(items);
        setPagination(pagination);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    tradingHistoryRequest();
  }, []);

  const onSubmit = (data?: any) => {
    if (!filters && !data) {
      return;
    }

    if (filters && !data) {
      tradingHistoryRequest({ current_page: 1 });
      setFilters(null);
    } else {
      setFilters(data);
      tradingHistoryRequest({ current_page: 1, ...data });
    }
  };

  return (
    <>
      <TradingHistoryFiltersWT isLoading={isLoading} onSubmit={onSubmit} />
      <div className={'trading-table'}>
        <TransactionHistoryWTTable
          data={tableData}
          perPage={pagination.per_page}
          isLoading={isLoading}
        />
        {tableData?.length ? (
          <TablePagination
            goToFirstPage={() => {
              tradingHistoryRequest({ current_page: 1, ...filters });
            }}
            goToPrevPage={() => {
              tradingHistoryRequest({ current_page: pagination.current_page - 1, ...filters });
            }}
            goToNextPage={() => {
              tradingHistoryRequest({ current_page: pagination.current_page + 1, ...filters });
            }}
            goToLastPage={() => {
              tradingHistoryRequest({ current_page: pagination.total_pages, ...filters });
            }}
            currentPage={pagination.current_page}
            pagesLength={pagination.total_pages}
            perPageChange={(per_page) => {
              setPagination((prev) => ({ ...prev, per_page, current_page: 1 }));
              tradingHistoryRequest({ per_page, current_page: 1, ...filters });
            }}
            goToSelectedPage={(current_page) => {
              setPagination((prev) => ({ ...prev, current_page }));
              tradingHistoryRequest({ current_page, ...filters });
            }}
            isFirstPageDisable={() => pagination.current_page === 1}
            isPrevPageDisable={() => pagination.current_page === 1}
            isNextPageDisable={() => pagination.current_page === pagination.total_pages}
            isLastPageDisable={() => pagination.current_page === pagination.total_pages}
          />
        ) : null}
      </div>
    </>
  );
};
