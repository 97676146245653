import React, { useEffect, useState } from 'react';
import { ArrowsClockwise } from 'phosphor-react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import AccountSelect from 'components/ui/AccountSelect/AccountSelect';
import Button from 'components/ui/Button/Button';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { useForm, useWatch } from 'react-hook-form';
import LKSelect from 'components/ui/LKSelect';
import { getTradingAccounts } from 'api/trading-account';
import '../TradingHistoryFilters/filter.scss';
import { getTradingHistorySymbols } from 'api/trading';

const defaultValues = { logins: null, symbol: null, state: null, open: '', close: '' };

const stateOptions = ['Position', 'Pending', 'History'].map((s) => ({ value: s, label: s }));

export const TradingHistoryFiltersWT = ({ isLoading, onSubmit }) => {
  const intl = useIntl();

  const [tradingAccounts, setTradingAccounts] = useState([]);
  const [symbolsOptions, setSymbolsOptions] = useState([]);

  const {
    control,
    getValues,
    reset,
    setValue,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({ defaultValues });

  const selectedTradingAcc = useWatch({ control, name: 'logins' });
  const selectedSymbol = useWatch({ control, name: 'symbol' });

  const isFormChange = () => Object.keys(dirtyFields).length;

  const resetForm = () => {
    onSubmit(null);
    reset(defaultValues);
  };

  const submit = (data: any) => {
    onSubmit({
      logins: data.logins?.value ? [data.logins.value] : undefined,
      symbols: data.symbol?.value ? [data.symbol.value] : undefined,
      state: data.state?.value ?? undefined,
      open_date_from: data.open?.from ?? undefined,
      open_date_to: data.open?.to ?? undefined,
      closing_date_from: data.close?.from ?? undefined,
      closing_date_to: data.close?.to ?? undefined,
    });
  };

  const tradingAccountsRequest = () => {
    getTradingAccounts()
      .then((response) => {
        setTradingAccounts(response.data.data);
      })
      .catch(console.log);
  };

  const symbolsRequest = (logins?: string[]) => {
    getTradingHistorySymbols(logins)
      .then((response) => {
        const options = response.data.data.tradedSymbols.map((s) => ({ label: s, value: s }));
        setSymbolsOptions(options);
      })
      .catch(console.log);
  };

  useEffect(() => {
    tradingAccountsRequest();
    symbolsRequest();
  }, []);

  useEffect(() => {
    if (selectedTradingAcc) {
      if (selectedSymbol) {
        setValue('symbol', null);
      }

      symbolsRequest([selectedTradingAcc.value]);
    }
  }, [selectedTradingAcc]);

  return (
    <div className={'trading-history__filter'}>
      <form onSubmit={handleSubmit(submit)} className={'filters'}>
        <Row>
          <Col md={4}>
            <AccountSelect
              name="logins"
              control={control}
              title={
                <FormattedMessage
                  id="page.trading.history.tradingAccount.title"
                  defaultMessage="Trading account"
                />
              }
              accountsData={tradingAccounts}
              controlPadding="3px 5px"
            />
          </Col>
          <Col md={4}>
            <LKSelect
              options={symbolsOptions}
              name="symbol"
              title={<FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />}
              control={control}
              controlPadding="3px 5px"
            />
          </Col>
          <Col md={4}>
            <LKSelect
              options={stateOptions}
              name="state"
              title={<FormattedMessage id="table.th.state" defaultMessage="State" />}
              control={control}
              controlPadding="3px 5px"
            />
          </Col>
          <Col md={4}>
            <div className={'datapicker'}>
              <DatePickerRange
                name="open"
                control={control}
                label={
                  <FormattedMessage
                    id="page.trading.history.range.open.title"
                    defaultMessage="Open time range"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'page.trading.history.range.placeholder',
                  defaultMessage: 'From - To',
                })}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className={'datapicker'}>
              <DatePickerRange
                control={control}
                name="close"
                label={
                  <FormattedMessage
                    id="page.trading.history.range.close.title"
                    defaultMessage="Close time range"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'page.trading.history.range.placeholder',
                  defaultMessage: 'From - To',
                })}
              />
            </div>
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={'filters__btns'}>
            <Button
              className={isLoading ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText={
                <FormattedMessage id="personalInfo.form.field.btn.reset" defaultMessage="Reset" />
              }
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange()}
            />
            <Button
              buttonText={
                <FormattedMessage id="page.trading.history.form.apply" defaultMessage="Apply" />
              }
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
